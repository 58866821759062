import { Injectable } from '@angular/core';
import { BaseService, HttpClientService } from './base.service';
import { GetPaymentCompanyResponse, PaymentRequest, PaymentResponse, SetPaymentCompanyRequest } from '../models/payment';
import { Keys, OrderOptions } from '../constants/keys';
import { Observable } from 'rxjs';
import { BaseResponseWrapper, PaginatedResponseWrapperOvi } from '../models/api-response-wrappers';
import { SharedService } from './shared.service';
import { warn } from 'console';
import { request } from 'http';
import { json, path } from '@angular-devkit/core';
import { CompanySearchParams, UserTotalDebt, CompanyPaymentResponse } from '../models/user';
import { map } from 'rxjs/operators';
import { constants } from 'os';
import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { HttpHeaders } from '@angular/common/http';

const Endpoints = {
   Controller: 'users/{userId}/payments',
   GetPayments: 'users/{userId}/payments?page={page}&size={size}&sort_by={sort_by}&order_by{order_by}',
   GetUpcoming: 'users/me/upcoming-payment?userId={userId}',
   UpdateUpcoming: 'users/me/update-payment',
   Pay: 'users/me/payments/{id}/pay?ipAddress={ipAddress}',
   Delete: 'payments/{id}',
   PayOfDebits: 'payments/userwithdrawmoney',


   GetPaymentUser: 'payments/paymentuser?userId={userId}',
   SetPaymentUser: 'payments/paymentuser',
   ReceiveLumpSumPayment: 'payments/receivelumpsumpayment',
   SendPaymentInformationAsync: 'payments/sendpaymentinformationasync',
   AllUsersPaymentsList: 'payments?page={page}&size={size}&sort_by={sort_by}&order_by={order_by}&',
   PaymentsTotaldebts: 'payments/totaldebts?',
   UserPaymentExcelImport: 'payments/excel-import',
   UserFilterPaymentExport: "payments/export-user-summary?page={page}&size={size}&sort_by={sort_by}&order_by={order_by}&",
   PaymentsPay: "payments/pay",
   PaymentsRefund: "payments/refund",
   Canceledpayment:"users/me/cancel-payment"

};

@Injectable()
export class PaymentService extends BaseService<PaymentRequest, PaymentResponse> {
   constructor(httpClient: HttpClientService, private sharedService: SharedService) {
      super(httpClient, Endpoints.Controller);
   }

   getUpcomingPayment(userId?: string): Observable<PaginatedResponseWrapperOvi<any>> {

      userId = userId != null ? userId : localStorage.getItem(Keys.USER_ID);
      const fullPath = (this.createUrlWithParams(Endpoints.GetUpcoming, { userId: userId }));
      return this.client.get<BaseResponseWrapper<any>>(fullPath);

   }

   getPayments(
      userId: string = localStorage.getItem(Keys.USER_ID),
      page: number = 0,
      size: number = 30,
      q: string = '',
      sort_by: any = '',
      order_by: string = OrderOptions.Asc,
   ): Observable<PaginatedResponseWrapperOvi<PaymentResponse>> {

      const json = {
         userId: userId,
         page: page,
         size: size,
         q: q,
         sort_by: sort_by,
         order_by: order_by,
      };

      const fullPath = this.createUrlWithParams(Endpoints.GetPayments, json);
      return this.client.get<PaginatedResponseWrapperOvi<PaymentResponse>>(fullPath);
   }

   createPayment(request: PaymentRequest, userId: string): Observable<BaseResponseWrapper<PaymentResponse>> {
      const url = this.createUrlWithParams(Endpoints.Controller, { userId: userId });
      const json = {
         ...request,
         // lastPaymentDate: request.lastPaymentDate.toISOString(),
         // beginDate: request.beginDate.toISOString(),
         // endDate: request.endDate.toISOString(),
      };
      return this.client.post<BaseResponseWrapper<PaymentResponse>>(url, JSON.stringify(json));
   }

   updateUpcomingPayment(request: any): Observable<BaseResponseWrapper<PaymentResponse>> {
      const url = this.createUrlWithParams(Endpoints.UpdateUpcoming);
      const json = {
         userId: localStorage.getItem(Keys.USER_ID),
         ...request
      };
      return this.client.post<BaseResponseWrapper<boolean>>(url, JSON.stringify(json));
   }

   pay(paymentId: string, ip: string): Observable<BaseResponseWrapper<PaymentResponse>> {
      const url = this.createUrlWithParams(Endpoints.Pay, { id: paymentId, ipAddress: ip });
      return this.client.post<BaseResponseWrapper<PaymentResponse>>(url);
   }

   deletePayment(paymentId: string): Observable<BaseResponseWrapper<null>> {
      const url = this.createUrlWithParams(Endpoints.Delete, { id: paymentId });
      return this.client.delete<BaseResponseWrapper<null>>(url);
   }

   getIp(): Promise<string> {
      return fetch('https://api.ipify.org')
         .then(x => x.text());
   }

   payOfDebits(userId: string): Observable<BaseResponseWrapper<PaymentResponse>> {
      const url = this.createUrlWithParams(Endpoints.PayOfDebits);
      return this.client.post<BaseResponseWrapper<PaymentResponse>>(url, JSON.stringify({ userId: userId }));
   }
   setPaymentUser(request: SetPaymentCompanyRequest): Observable<BaseResponseWrapper<SetPaymentCompanyRequest>> {
      const url = this.createUrlWithParams(Endpoints.SetPaymentUser);
      const json = {
         ...request
      };

      return this.client.post<BaseResponseWrapper<SetPaymentCompanyRequest>>(url, JSON.stringify(json));
   }
   getPaymentUser(userID: any): Observable<BaseResponseWrapper<GetPaymentCompanyResponse>> {
      const fullPath = (this.createUrlWithParams(Endpoints.GetPaymentUser, { userId: userID }));
      return this.client.get<PaginatedResponseWrapperOvi<GetPaymentCompanyResponse>>(fullPath);
   }
   uploadExcelImport(request: File): Observable<any> {
      const dataToPost = new FormData();
      dataToPost.append("file", request, request.name);
      const fullPath = this.createUrlWithParams(Endpoints.ReceiveLumpSumPayment);
      return this.client.customPostMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
   }
   sendSms(message: string): Observable<BaseResponseWrapper<any>> {
      let fullPath = this.createUrlWithParams(Endpoints.SendPaymentInformationAsync);

      const json = {
         message: message,
      };
      if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

      return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
   }
   getUsersPaymentsList(
      page: number = 0,
      size: number = 30,
      sort_by: any = 'id',
      order_by: string = OrderOptions.Desc,
      params = new CompanySearchParams(),
   ): Observable<PaginatedResponseWrapperOvi<CompanyPaymentResponse>> {
      const json = {
         page: page,
         size: size,
         sort_by: sort_by,
         order_by: order_by,
      };
      let path = this.createUrlWithParams(Endpoints.AllUsersPaymentsList, json);
      Object.keys(params).forEach(x => {
         if (params[x] !== undefined && params[x] !== null) {
            const qParam = `${x}=${params[x]}&`;
            path += qParam;
         }
      });
      if (path.endsWith('&')) path = path.slice(0, -1);
      return this.client.get<PaginatedResponseWrapperOvi<CompanyPaymentResponse>>(path)
   }
   //UserSerach Paremetre olarak gidecek
   getPaymentsTotaldebts(params = new CompanySearchParams()): Observable<BaseResponseWrapper<UserTotalDebt>> {
      const json = {
         params: params
      }

      let path = this.createUrlWithParams(Endpoints.PaymentsTotaldebts, json);
      Object.keys(params).forEach(x => {
         if (params[x] !== undefined && params[x] !== null) {
            const qParam = `${x}=${params[x]}&`;
            path += qParam;
         }
      });

      if (path.endsWith('&')) path = path.slice(0, -1);
      return this.client.get<BaseResponseWrapper<UserTotalDebt>>(path)
   }
   uploadExcelImportUserPayment(request: File): Observable<any> {
      const dataToPost = new FormData();
      dataToPost.append("file", request, request.name);
      const fullPath = this.createUrlWithParams(Endpoints.UserPaymentExcelImport);
      return this.client.customPostMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
   }
   exportFilterUser(
      page: number = 0,
      size: number = 30,
      sort_by: any = 'id',
      order_by: string = OrderOptions.Desc,
      params = new CompanySearchParams()
   ): Observable<any> {
      const json = {
         page: page,
         size: size,
         sort_by: sort_by,
         order_by: order_by,
         params: params
      };
      let fullPath = this.createUrlWithParams(Endpoints.UserFilterPaymentExport, json);

      Object.keys(params).forEach(x => {
         if (params[x] !== undefined && params[x] !== null) {
            const qParam = `${x}=${params[x]}&`;
            fullPath += qParam;
         }
      });

      if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
      return this.client.get(fullPath, true);
   }
   PaymentsPay(_request: any): Observable<BaseResponseWrapper<any>> {
      const json = {
         ..._request
      }
      const path = this.createUrlWithParams(Endpoints.PaymentsPay)
      return this.client.post<any>(path, JSON.stringify(json))
   }
   PaymentRefund(_request: any): Observable<BaseResponseWrapper<any>> {
      const json = {
         ..._request
      }
      const path = this.createUrlWithParams(Endpoints.PaymentsRefund)
      return this.client.post<any>(path, JSON.stringify(json))
   }
   CancelPayment(request: any): Observable<BaseResponseWrapper<PaymentResponse>> {
      const url = this.createUrlWithParams(Endpoints.Canceledpayment);
      const json = {
         userId: localStorage.getItem(Keys.USER_ID),
         ...request
      };
      return this.client.post<BaseResponseWrapper<boolean>>(url, JSON.stringify(json));
   }
}