import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AccountingPartyGroupResponse, CompanyGroupRequest } from '../models/company';
import { Keys } from '../constants/keys';
import { BaseService, HttpClientService } from './base.service';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';

const Endpoints = {
   Controller: 'users/{userId}/company-groups',
   CompanyGroupGroups: 'users/{id}/company-groups?type={type}&page={page}&size={size}&q={q}&sort_by={sort_by}&order_by={order_by}',
   AccountingPartyGroup: 'users/{userId}/company-groups/{id}',
};

@Injectable()
export class CompanyGroupService extends BaseService<any, AccountingPartyGroupResponse> {

   constructor(httpClient: HttpClientService) {
      super(httpClient, Endpoints.Controller);
   }

   getCompanyGroupGroups(
      q: string = '',
      userId: string = localStorage.getItem(Keys.USER_ID),
   ): Observable<PaginatedResponseWrapperOvi<AccountingPartyGroupResponse>> {

      const json = {
         userId: userId,
         q: q,
      };

      const fullPath = this.createUrlWithParams(Endpoints.Controller, json);
      return this.client.get<PaginatedResponseWrapperOvi<AccountingPartyGroupResponse>>(fullPath);
   }

   createCompanyGroup(request: CompanyGroupRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<AccountingPartyGroupResponse>> {
      const url = this.createUrlWithParams(Endpoints.Controller, { userId: userId });
      return this.client.post<BaseResponseWrapper<AccountingPartyGroupResponse>>(url, JSON.stringify(request));
   }

   getCompanyGroup(companyId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<AccountingPartyGroupResponse>> {
      const fullPath = (this.createUrlWithParams(Endpoints.AccountingPartyGroup, { userId: userId, id: companyId }));
      return this.client.get<BaseResponseWrapper<AccountingPartyGroupResponse>>(fullPath);
   }

   updateCompanyGroup(companyId: string, request: CompanyGroupRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<AccountingPartyGroupResponse>> {
      const url = this.createUrlWithParams(Endpoints.AccountingPartyGroup, { userId: userId, id: companyId });
      return this.client.put<BaseResponseWrapper<AccountingPartyGroupResponse>>(url, JSON.stringify(request));
   }

   deleteCompanyGroup(companyId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<any>> {
      const url = this.createUrlWithParams(Endpoints.AccountingPartyGroup, { id: companyId, userId: userId });
      return this.client.delete<BaseResponseWrapper<any>>(url);
   }
}
