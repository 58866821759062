import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponseWrapper } from '../models/api-response-wrappers';
import { LanguageResponse } from '../models/common';
import { BaseService, HttpClientService } from './base.service';
import { SectorResponse } from '../models/sector';
import { Keys } from '../constants/keys';
import { CompanySearchParams } from '../models/user';

const Endpoints = {
    Controller: 'common',
    GetSupportedLanguages: 'common/supported-languages',
    Sector: 'sectors',
    Notification: 'notifications?',
    Sms: 'notifications/sms?',
};

@Injectable()
export class CommonService extends BaseService<any, any> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    GetSupportedLanguages(): Observable<BaseResponseWrapper<LanguageResponse[]>> {
        const url = this.createUrlWithParams(Endpoints.GetSupportedLanguages);
        return this.client.get(url);
    }

    getSectors(): Observable<BaseResponseWrapper<SectorResponse[]>> {
        const fullPath = this.createUrlWithParams(Endpoints.Sector);
        return this.client.get<BaseResponseWrapper<SectorResponse[]>>(fullPath);
    }


    sendNotification(message: string, title: string = null, params = new CompanySearchParams()): Observable<BaseResponseWrapper<any>> {
        let fullPath = this.createUrlWithParams(Endpoints.Notification);

        const json = {
            title: title,
            message: message,
        };

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
    }

    sendSms(message: string, params = new CompanySearchParams(),): Observable<BaseResponseWrapper<any>> {
        let fullPath = this.createUrlWithParams(Endpoints.Sms);

        const json = {
            message: message,
        };

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
    }
}
