import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Keys } from '../constants/keys';
import { LanguageResponse } from '../models/common';
import { CompanyResponse, Relation, CompanySimpleResponse } from '../models/user';
import { ReplaySubject } from 'rxjs';
import { RegisterRequest } from '../models/account';
import { isNullOrUndefined } from 'util';
import { UserService } from './user.service';

@Injectable()
export class SharedService {

    public userData: CompanyResponse;
    public userToken: string;
    public userId: string;
    public language: string;
    public supportedLanguages: LanguageResponse[];
    changedUser: ReplaySubject<any> = new ReplaySubject<1>();
    currentCompany: ReplaySubject<any> = new ReplaySubject<1>();
    registerRequest: ReplaySubject<any> = new ReplaySubject<1>();
    registerWizardStep: ReplaySubject<any> = new ReplaySubject<1>();

    calendarTr = {
        firstDayOfWeek: 1,
        dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
        dayNamesShort: ["Pzr", "Pzt", "Sal", "Çrş", "Prş", "Cma", "Cmt"],
        dayNamesMin: ["Pzr", "Pzt", "Sal", "Çrş", "Prş", "Cma", "Cmt"],
        monthNames: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        monthNamesShort: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        today: 'Bugün',
        clear: 'Temizle',
        dateFormat: 'dd/mm/yyyy',
        weekHeader: 'Hf.'
    };

    constructor(
        private router: Router,
        private userService: UserService
    ) {
        this.userData = JSON.parse(localStorage.getItem(Keys.USER_DATA));
        this.userToken = localStorage.getItem(Keys.USER_TOKEN);
        this.userId = localStorage.getItem(Keys.USER_ID);
        this.language = localStorage.getItem(Keys.LANGUAGE);
        this.supportedLanguages = JSON.parse(localStorage.getItem(Keys.SUPPORTED_LANGUAGES));
    }

    setUserToken(token: string) {
        localStorage.setItem(Keys.USER_TOKEN, token);
        this.userToken = localStorage.getItem(Keys.USER_TOKEN);
    }

    setUserData(data: CompanyResponse) {
        localStorage.setItem(Keys.USER_DATA, JSON.stringify(data));
        this.userData = data;
    }

    setUserId(id: string) {
        localStorage.setItem(Keys.USER_ID, id);
        this.userId = id;
    }

    userControl() {
        this.userService.getItem(this.userData.id).subscribe(x => {
            if (x.data) {
                if (x.data.email !== this.userData.email || x.data.isActive !== this.userData.isActive) {
                    this.logOut();
                }
            }
        });
    }
    setCurrentCompany(company: Relation) {
        this.currentCompany.next(company);
        localStorage.setItem(Keys.CURRENT_COMPANY, JSON.stringify(company));
    }

    changedUserData(userData: CompanyResponse) {
        this.changedUser.next(userData);
        this.setUserData(userData);
    }

    async getMyCompanies(): Promise<Relation[]> {
        const myCompanies: Relation[] = [...this.userData.receivedRelations];

        if (!isNullOrUndefined(this.userData.companyName)) {
            const company: Relation = <Relation>{};
            const user = <CompanySimpleResponse>{};

            user.companyName = this.userData.companyName;
            user.id = 'me';

            company.mainCompany = <CompanySimpleResponse>{};
            company.mainCompany = user;
            company.readCompanies = true;
            company.writeCompanies = true;
            company.readTransactions = true;
            company.writeTransactions = true;
            company.readProducts = true;
            company.writeProducts = true;
            company.readUsers = true;
            company.writeUsers = true;

            myCompanies.push(company);
        }
        return myCompanies;
        return myCompanies.sort((a, b) => (a.mainCompany.companyName > b.mainCompany.companyName) ? 1 : -1);
    }

    setLanguage(language: string) {
        localStorage.setItem(Keys.LANGUAGE, language);
        this.language = localStorage.getItem(Keys.LANGUAGE);
    }

    setSupportedLanguages(data: LanguageResponse[]) {
        localStorage.setItem(Keys.SUPPORTED_LANGUAGES, JSON.stringify(data));
        this.supportedLanguages = JSON.parse(localStorage.getItem(Keys.USER_DATA));
    }

    logOut() {
        localStorage.clear();
        this.router.navigate(['/account/login']);
    }

    changeRegisterRequest(request: RegisterRequest) {
        this.registerRequest.next(request);
    }

    changeRegisterWizardStep(step: any) {
        this.registerWizardStep.next(step);
    }
    setUserCompanyData(data: any) {
        this.userService.getUserCompanyDetail(data).subscribe(x => {
            localStorage.setItem(Keys.COURIER_DETAIL, JSON.stringify(x));
        },
            err => {
                localStorage.removeItem(Keys.COURIER_DETAIL)
            })
    }


}
