import { Injectable } from "@angular/core";
import { BaseService, HttpClientService } from "./base.service";
import { CourierReconciliationSchemaDto } from "../models/courierReconciliation/courier-reconciliation-schema-dto";
import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";
import { CourierReconciliationProgressPaymentDto } from "../models/courierReconciliation/courier-reconciliation-progress-payment-dto";
import { BaseResponseWrapper } from "../models/api-response-wrappers";

const Endpoints = {
    Controller: 'courier/reconciliation',
    List: 'courier/reconciliation/list/{progressPaymentKey}?include={include}&page={page}&pageSize={pageSize}&sortBy={sortBy}&sortDirection={sortDirection}&filter={filter}',
    ListFundamental: 'courier/reconciliation/list-fundamental/?page={page}&pageSize={pageSize}&sortBy={sortBy}&sortDirection={sortDirection}&filter={filter}',
    ListExport: 'courier/reconciliation/list/{progressPaymentKey}/export?include={include}&sortBy={sortBy}&sortDirection={sortDirection}&filter={filter}',
    Summary: 'courier/reconciliation/list/{progressPaymentKey}/summary?filter={filter}',
    Approve: 'users/approve-reconciliation/{reconciliationKey}',
    ListSchema: 'courier/reconciliation/schema',
    ListProgressPayment: 'courier/reconciliation/progress-payment/list/{schemaKey}',
    CourierQueryGetList: 'courier/company/externalid-pairing',
    CourierQueryExcelImport: 'courier/company/externalid-pairing',
    CourierQueryExcelExport: 'courier/company/externalid-pairing/excel',
    NewListExport: 'courier/reconciliation/list-fundamental/export?include={include}&sortBy={sortBy}&sortDirection={sortDirection}&filter={filter}',
};

@Injectable()
export class CourierReconciliationService extends BaseService<any, any> {
    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    list(progressPaymentKey: string, include: string, page: number, pageSize: number, sortBy: string, sortDirection: string, filter?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.List, { progressPaymentKey, include, filter, page, pageSize, sortBy, sortDirection });
        return this.client.get<any>(fullPath);
    }

    listFundamental(page: number, pageSize: number, sortBy: string, sortDirection: string, filter?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ListFundamental, { filter, page, pageSize, sortBy, sortDirection });
        return this.client.get<any>(fullPath);
    }

    listExport(progressPaymentKey: string, include: string, sortBy: string, sortDirection: string, filter?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ListExport, { progressPaymentKey, include, filter, sortBy, sortDirection });
        return this.client.getRaw<any>(fullPath);
    }

    summary(progressPaymentKey: string, filter?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.Summary, { progressPaymentKey, filter });
        return this.client.get<any>(fullPath);
    }

    approve(reconciliationKey: string) {
        const fullPath = this.createUrlWithParams(Endpoints.Summary, { reconciliationKey });
        return this.client.post<any>(fullPath);
    }

    listSchema(): Observable<CourierReconciliationSchemaDto[]> {
        const fullPath = this.createUrlWithParams(Endpoints.ListSchema);
        return this.client.get<any>(fullPath).pipe(map((response): CourierReconciliationSchemaDto[] => {
            return response.items as CourierReconciliationSchemaDto[];
        }));
    }

    listProgressPayment(schemaKey: string): Observable<CourierReconciliationProgressPaymentDto[]> {
        const fullPath = this.createUrlWithParams(Endpoints.ListProgressPayment, { schemaKey });
        return this.client.get<CourierReconciliationProgressPaymentDto[]>(fullPath);
    }
    courierQueryList():Observable<BaseResponseWrapper<any>>{
        const path = this.createUrlWithParams(Endpoints.CourierQueryGetList);
        return this.client.get<BaseResponseWrapper<any>>(path);
    }
    courierQueryListExportExcel():Observable<any>{
        const path = this.createUrlWithParams(Endpoints.CourierQueryExcelExport);
          return this.client.get(path , true);
    }
    courierQueryImportExcel(request: File):Observable<any>{
        const dataToPost = new FormData();
        dataToPost.append("file", request, request.name);
        const fullPath = this.createUrlWithParams(Endpoints.CourierQueryExcelImport);
        return this.client.postMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
    }
    newListExport(include: string, sortBy: string, sortDirection: string, filter?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.NewListExport, {include, filter, sortBy, sortDirection });
        return this.client.getRaw<any>(fullPath);
    }
}