import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../@core/services/shared.service';
import { Relation, CompanyResponse } from '../../../@core/models/user';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { Keys } from '../../../@core/constants/keys';
import { BaseComponent } from '../../../shared/base.component';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../../@core/services/user.service';
import { NotificationService } from '../../../@core/services/notification.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnDestroy {

  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  autoCompactSidebar: boolean = false;
  user: CompanyResponse;
  hasPreviousUser: boolean = false;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'coporate';

  userMenu: NbMenuItem[] = [];

  languages = [{}] as [{ value: string, name: string; }];
  currentLanguage: string;

  myCompanies: Relation[] = [];
  currentCompany: Relation;
  selectedCompany: any;
  companies: SelectItem[] = [];

  shutdownDate = new Date();
  loading: boolean = false;
  isMenuShow: boolean = false;
  pathAdress:string = null;
  isMenuIconVisible:boolean = false;
  constructor(
    protected userService: UserService,
    protected dialogService: NbDialogService,
    protected notificationService: NotificationService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    public sharedService: SharedService,
    private translateService: TranslateService,
    private router: Router,
  ) {

    super(translateService);

    const previousToken: any = localStorage.getItem(`${Keys.USER_TOKEN}_PREVIOUS`);
    const previousUserData: any = localStorage.getItem(`${Keys.USER_DATA}_PREVIOUS`);
    const previousUserId: any = localStorage.getItem(`${Keys.USER_ID}_PREVIOUS`);

    this.hasPreviousUser = previousToken !== null && typeof (previousToken) !== undefined && previousUserData !== null && typeof (previousUserData) !== undefined && previousUserId !== null && typeof (previousUserId) !== undefined;

    this.user = this.sharedService.userData;

    // this.menuService.onItemSelect().subscribe(
    //   (event: { tag: string, item: any }) => {
    //     this.sidebarService.toggle(true, 'menu-sidebar');
    //   });
    if(this.sharedService.userData.roles[0].name === 'courierAgentBasic'){
      this.pathAdress = "/admin/courier/courier-query";
      this.isMenuIconVisible = true;
    }
    else{
      this.pathAdress = "/";
    }

    this.menuService.onItemClick().subscribe(
      x => {
        if (x.item.data === 'logOut') {
          this.sharedService.logOut();
        } else if (x.item.url === '/profile') {
          this.router.navigate[x.item.url];
        } else if (x.item.data === 'shutdown') {
          this.openStatusDialog(this.dialog);
        } else {
          if (this.autoCompactSidebar) {
            this.sidebarService.toggle(true, "menu-sidebar");
          }
        }
      });

    this.currentLanguage = translateService.currentLang;

    translateService.getLangs()
      .forEach(x => this.languages.push({ name: translateService.instant('LANGUAGES.' + x), value: x }));

    this.userMenu = [];
    this.userMenu.push(
      { title: this.getTranslate('COMMON.PROFILE'), link: '/profile' },
      { title: this.getTranslate('COMMON.CONTRACTS'), link: '/contracts', hidden: this.isUserValidForRoles('courierAgentBasic') },
      { title: this.getTranslate('PAGES.PAYMENTS.TITLE'), link: 'account/payments',hidden: this.isUserValidForRoles('courierAgentBasic') },
      { title: this.getTranslate('PAGES.PAYMENT_METHODS.TITLE'), link: 'account/payment-methods', hidden: this.isUserValidForRoles('courierAgentBasic') },
      { title: this.getTranslate('CHANGE-PASSWORD.TITLE'), link: '/change-password' },
      { title: this.getTranslate('COMMON.SHUTDOWN'), data: 'shutdown', hidden: this.isUserValidForRoles('courierAgentBasic') },
      { title: this.getTranslate('COMMON.LOGOUT'), data: 'logOut', },
    );


    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.userPictureOnly = isLessThanXl;
        this.autoCompactSidebar = isLessThanXl;
      });

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    if (this.isUserValidForRoles('member')) {
      this.sharedService.getMyCompanies().then(x => {
        x.forEach(y => {
          if (y.mainCompany == null) {
            x = x.filter(z => z.mainCompany != y.mainCompany);
          }
        });
        this.myCompanies = x;

        const mainCompany = x.find(c => c.mainCompanyId === this.user.id);
        const foundedCurrentCompany = localStorage.getItem(Keys.CURRENT_COMPANY);
        this.currentCompany = !isNullOrUndefined(foundedCurrentCompany)
          ? JSON.parse(foundedCurrentCompany)
          : (mainCompany ? mainCompany : x[0]);

        this.selectedCompany = this.currentCompany;

        if (this.currentCompany) {
          this.sharedService.setCurrentCompany(this.currentCompany);
          this.sharedService.setUserId(this.currentCompany.mainCompany.id);
        }

        x.forEach(x => {
          this.companies.push({
            label: (x.mainCompany.companyName ? x.mainCompany.companyName : (x.mainCompany.firstName + ' ' + x.mainCompany.lastName)).toUpperCase() + (x.mainCompany.phoneNumber ? ' - ' + x.mainCompany.phoneNumber : ''),
            value: x,
          });
        });
      });
    }

  }

  changeLanguage(e) {
    window.location.reload();
    this.translateService.use(e);
    this.sharedService.setLanguage(e);
  }

  returnToPreviousUser() {
    this.sharedService.setUserToken(localStorage.getItem(`${Keys.USER_TOKEN}_PREVIOUS`));
    this.sharedService.setUserData(JSON.parse(localStorage.getItem(`${Keys.USER_DATA}_PREVIOUS`)));
    this.sharedService.setUserId(localStorage.getItem(`${Keys.USER_ID}_PREVIOUS`));
    localStorage.removeItem(`${Keys.COURIER_DETAIL}`);
    localStorage.removeItem(`${Keys.USER_TOKEN}_PREVIOUS`);
    localStorage.removeItem(`${Keys.USER_DATA}_PREVIOUS`);
    localStorage.removeItem(`${Keys.USER_ID}_PREVIOUS`);
    this.router.navigate(['/dashboard']);
    window.location.href = "/";
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeDate() { }

  openStatusDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: this.getTranslate('COMMON.SHUTDOWN-INFO'),
    });
  }

  shutdown() {
    this.loading = true;
    this.userService.shutdownUserAccount(this.shutdownDate).subscribe(t => {
      this.sharedService.logOut();
      this.loading = false;
    }, err => {
      this.notificationService.showErrorToast(err.message);
      this.loading = false;
    });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  changeCompany(companyId: any) {
    const foundedCompany = this.myCompanies.find(c => c.mainCompany.id === companyId);
    this.currentCompany = !isNullOrUndefined(foundedCompany) ? foundedCompany : this.myCompanies[0];
    this.sharedService.setCurrentCompany(this.currentCompany);
    this.sharedService.setUserId(this.currentCompany.mainCompany.id);
    window.location.reload();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    // console.log("!!");
    this.isMenuShow = (this.isMenuShow) ? false : true;

    return false;
  }

  navigateHome() {
    this.router.navigate(['/dashboard']);
  }

  openSupport() {
    window.open('https://bimasrafmh.azurewebsites.net/index.php/tur/chat/start/(leaveamessage)/true?prefill%5Busername%5D=' + this.sharedService.userData.companyName);
  }
}
