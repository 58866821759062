import { IResponse } from './api-response-wrappers';

export interface AddressResponse {
    countryId: number | null;
    country: string | null;
    cityId: number | null;
    city: string | null;
    districtId: number;
    district: string;
    county: string | null;
    addressLine: string;
    id: string;
}

export class AddressRequest {
    countryId: number | null = null;
    country: string | null = '';
    cityId: number | null = null;
    city: string | null = '';
    districtId: number | null = null;
    district: string | null;
    county: string | null = '';
    addressLine = '';
    id: string;
}

export interface AccountingPartyResponse extends IResponse {
    name: string;
    email: string;
    phoneNumber: string;
    type: CompanyType;
    iban: string;
    address: AddressResponse;
    identityNo: string;
    taxNo: string;
    taxOffice: string;
    contactFirstName: string;
    contactLastName: string;

    isCreatedByAdmin: boolean;

    companyGroupId: string;
    accountingPartyGroup: AccountingPartyGroupResponse;
    // Edefter
    eDefterDefaultVergiDairesiKodu: string;
    eDefterDefaultAlisTuruKodu: string;
    eDefterDefaultGelirKayitAltTuruKodu: string;
    eDefterDefaultGelirKayitTuruKodu: string;
    eDefterDefaultGiderKayitAltTuruKodu: string;
    eDefterDefaultGiderKayitTuruKodu: string;
    eDefterDefaultSatisTuruKodu: string;

    isFinalConsumer: boolean;
}

export class NewCompany implements AccountingPartyResponse{
    name: string;
    email: string;
    phoneNumber: string;
    type: CompanyType;
    iban: string;
    address: AddressResponse;
    identityNo: string;
    taxNo: string;
    taxOffice: string;
    contactFirstName: string;
    contactLastName: string;
    isCreatedByAdmin: boolean;
    companyGroupId: string;
    accountingPartyGroup: AccountingPartyGroupResponse;
    eDefterDefaultVergiDairesiKodu: string;
    eDefterDefaultAlisTuruKodu: string;
    eDefterDefaultGelirKayitAltTuruKodu: string;
    eDefterDefaultGelirKayitTuruKodu: string;
    eDefterDefaultGiderKayitAltTuruKodu: string;
    eDefterDefaultGiderKayitTuruKodu: string;
    eDefterDefaultSatisTuruKodu: string;
    isFinalConsumer: boolean;
    id: string;
    isActive: boolean;
    createDate: Date;

}

export enum CompanyType {
    // 0
    Supplier = 'Supplier',
    // 1
    Customer = 'Customer',
    // 2
    Both = 'Both'
}

export class AccountingPartyRequest {
    name = null;
    email = null;
    phoneNumber = null;
    type = null;
    iban = null;
    address = new AddressRequest();
    identityNo: string = null;
    taxNo: string = null;
    taxOffice = null;
    contactFirstName = null;
    contactLastName = null;
    companyGroupId = null;
    id = null;
    customerType?: number = null;
    invoiceAlias?: string = null;
    isFromELedgerDeclaration: boolean = false;
}

export class CompanyGroupRequest {
    name = '';
}

export interface AccountingPartyGroupResponse extends IResponse {
    name: string;
}
export class CompanyDataTransport {
    selectedId: string;
    url: string;
    companyType: CompanyType;
    searchParams: string;
}

export enum AccountingPartyTypeEnum {
    Supplier = 0,
    Customer = 1,
    Both = 2
}