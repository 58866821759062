import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country } from '../../models/geographical/country';
import { City } from '../../models/geographical/city';
import { District } from '../../models/geographical/district';
import { AddressRequest, AccountingPartyRequest, AccountingPartyResponse } from '../../models/company';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../services/company.service';
import { CompanyGroupService } from '../../services/company-group.service';
import { GeographicalService } from '../../services/geographical.service';
import { UserService } from '../../services/user.service';
import { CompanyResponse } from '../../models/user';
import { async } from '@angular/core/testing';

@Component({
  selector: 'country-city-state-auto-complate',
  templateUrl: './country-city-state-auto-complate.component.html',
  styleUrls: ['./country-city-state-auto-complate.component.scss']
})
export class CountryCityStateAutoComplateComponent implements OnInit {

  @Output() customerCountryAddress = new EventEmitter<AddressRequest>();
  @Input() getCustomerId?: string;
  // companyResponse: CompanyResponse = <CompanyResponse>{};
  // companyRequest = new CompanyRequest();
  companyAddressRequest = new AddressRequest();
  countries: Country[];
  filteredCountries: Country[];
  countryAutocompleteValue: Country | null;
  cities: City[];
  filteredCities: City[];
  cityAutocompleteValue: any;
  districts: District[];
  filteredDistricts: District[];
  districtAutocompleteValue: any;
  companyResponse: CompanyResponse = <CompanyResponse>{};

  customerId: string | null = null;
  selectedCompanyGroup: any;
  constructor(
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected companyService: CompanyService,
    protected companyGroupService: CompanyGroupService,
    protected geographicalService: GeographicalService,
    protected userService: UserService,
  ) { }

  async ngOnInit() {
    this.customerId = this.getCustomerId;
    this.countries = await this.geographicalService.listCountries();
    if (this.customerId === undefined) {
      this.countryAutocompleteValue = new Country();
      // this.companyResponse.address.country = "Türkiye";
      this.countryAutocompleteValue.name = "Türkiye";
      this.companyAddressRequest.country = "Türkiye";
      this.countryAutocompleteValue.id = 1;

      await this.countrySelected(this.countryAutocompleteValue);
    }
    else
      await this.getCustomer();
  }

  async getCustomer(): Promise<void> {

    this.userService.getUserWithId(this.getCustomerId, false).subscribe(async (t) => {
      this.companyAddressRequest = t.data.address ? t.data.address : new AddressRequest();
      const country: string = this.companyAddressRequest.country;
      const city: string = this.companyAddressRequest.city;
      const district: string = this.companyAddressRequest.county;

      this.countryAutocompleteValue = this.countries.filter(x => x.name.toLowerCase().indexOf(country.toLowerCase()) >= 0)[0];
      await this.countrySelected(this.countryAutocompleteValue);


      this.cityAutocompleteValue = this.cities.filter(x => x.name.toLowerCase().indexOf(city.toLowerCase()) >= 0)[0];
      if (this.cityAutocompleteValue != null) {

        await this.citySelected(this.cityAutocompleteValue);
      }
      if (this.districts != undefined) {
        this.districtAutocompleteValue = this.districts.filter(x => x.name.toLowerCase().indexOf(district.toLowerCase()) >= 0)[0];
        if (this.districtAutocompleteValue != null) {
          await this.districtSelected(this.districtAutocompleteValue);
        }
      }


    }, err => {
      this.notificationService.showErrorToast(this.translateService.instant(err ? err : 'COMMON.RESOURCE-NOT-FOUND'));
    });
  }

  countriesAutocomplete(event: any): void {
    const query: string = event.query.normaliseForComparison().toLowerCase();
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().normaliseForComparison().indexOf(query.toLowerCase()) >= 0);

    if (this.filteredCountries.length > 0) {
      this.filteredCountries.forEach(item => this.countrySelected(item))

    }

  }

  async countrySelected(country: Country) {
    if (this.companyAddressRequest.countryId !== country.id) {
      this.companyAddressRequest.cityId = null;
      this.companyAddressRequest.city = "";
      this.companyAddressRequest.districtId = null;
      this.companyAddressRequest.district = "";
      this.companyAddressRequest.county = "";
      this.cityAutocompleteValue = "";
      this.districtAutocompleteValue = "";
    }

    this.companyAddressRequest.countryId = country.id;
    this.companyAddressRequest.country = country.name;
    this.customerCountryAddress.emit(this.companyAddressRequest);
    this.cities = await this.geographicalService.listCities(country.id);
  }

  citiesAutocomplete(event: any): void {
    const query: string = event.query.normaliseForComparison().toLowerCase();
    this.filteredCities = this.cities.filter(x => x.name.toLowerCase().normaliseForComparison().indexOf(query.toLowerCase()) >= 0);
    if (this.filteredCities.length > 0) {
      this.filteredCities.forEach(item => this.citySelected(item))
    }
  }

  async citySelected(city: City) {
    if (this.companyAddressRequest.cityId !== city.id) {
      this.companyAddressRequest.districtId = null;
      this.companyAddressRequest.county = "";
      this.companyAddressRequest.district = "";
      this.districtAutocompleteValue = "";
    }

    this.companyAddressRequest.cityId = city.id;
    this.companyAddressRequest.city = city.name;
    this.customerCountryAddress.emit(this.companyAddressRequest);
    this.districts = await this.geographicalService.listDistricts(city.id);
  }

  districtsAutocomplete(event: any): void {
    const query: string = event.query.normaliseForComparison().toLowerCase();
    this.filteredDistricts = this.districts.filter(x => x.name.toLowerCase().normaliseForComparison().indexOf(query.toLowerCase()) >= 0);
    if (this.filteredDistricts.length > 0) {
      this.filteredDistricts.forEach(item => this.districtSelected(item))
    }
  }

  districtSelected(district: District) {
    this.companyAddressRequest.districtId = district.id;
    this.companyAddressRequest.county = district.name;
    this.companyAddressRequest.district = district.name;
    this.customerCountryAddress.emit(this.companyAddressRequest);
  }


}
